import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Button from "@atom/Button";
import Icon from "@atom/Icon";
import Input from "@atom/Input";
import Modal from "@atom/Modal";
import { Heading } from "@atom/Typography";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";
import axios from "axios";

export default () => {
  let location = useLocation().pathname.substring(1);
  const history = useHistory();
  const [finishContact, setFinishContact] = useState(false);
  const [formInput, setFormInput] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    axios
      .post("/api/sendMail.php", formInput)
      .then((response) => {
        if (response.data && response.data.success) {
          setSubmitted(false);
          setFinishContact(true);
          setFormInput([]);
        }
      })
      .catch(function (error) {
        console.warn("Request Error:", error);
        setSubmitted(false);
      });
  };

  const handleChange = (e, name) => {
    setFormInput((prevState) => ({
      ...prevState,
      [name]: e,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleChange(data[location].title, "formName");
    handleChange("", "q2");
  }, []);

  function finalClose() {
    setFinishContact(false);
    history.push("/");
  }

  return (
    <div className={cn(style.forms)}>
      <Heading type={"h2"} weight={900}>
        {data[location].title}
      </Heading>
      <form
        className={cn(style.form)}
        name={"ContactUsForm"}
        onSubmit={(e) => handleSubmit(e)}
      >
        {data[location].input.map((value, i) => (
          <Input
            {...value}
            key={i}
            name={value.name}
            className={cn(style.item)}
            value={formInput[value.name] || ""}
            onChange={(e) => handleChange(e.target.value, value.name)}
            required
          />
        ))}
        <Button disabled={submitted} type={"submit"}>
          {"Submit"}
        </Button>
      </form>
      {finishContact && (
        <Modal
          show={finishContact}
          close={() => finalClose()}
          id={"Contact"}
          className={cn(style.modal)}
        >
          <div>
            <Icon icon={"check"} className={cn(style.check)} />
            <Heading serif type={"h3"}>
              Thank you for answering our survey!
            </Heading>
            <Heading serif type={"h4"} weight={500}>
              <br />
              Responses will be used to inform ongoing efforts to better
              understand the state of LGBTQ+ health experiences.
            </Heading>
          </div>
        </Modal>
      )}
    </div>
  );
};
