import React from "react";
import style from "./style.scss";
import cn from "classnames";

const main =
  "© 2023 Optum, Inc. All rights reserved. Optum® is a registered trademark of Optum, Inc.";
const alternative =
  "Optum® is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. All other brand or product names are trademarks or registered marks or the property of their respective owners. Optum is an equal opportunity employer.";

const Footer = ({ mainText }) => (
  <div className={cn(style.root)}>
    <div className={cn(style.footer, !mainText && style.alternative)}>
      <p>{mainText ? main : alternative}</p>
    </div>
  </div>
);

export default Footer;
