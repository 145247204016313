import React, { useEffect } from "react";
import Icon from "../Icon";
import style from "./style.scss";
import cn from "classnames";

export default ({ close, children, className }) => {
  function disableScroll() {
    // Get the current page scroll position
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    // if any scroll is attempted,
    // set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  function enableScroll() {
    window.onscroll = function () {};
  }

  useEffect(() => {
    disableScroll();
  }, []);

  const closeModal = () => {
    enableScroll();
    close();
  };

  return (
    <div className={cn(style.modal)}>
      <div className={cn(style.space)} onClick={() => closeModal()} />
      <div className={cn(style.content, className)}>
        <Icon
          icon="x"
          className={cn(style.close)}
          onClick={() => closeModal()}
        />
        {children}
      </div>
    </div>
  );
};
