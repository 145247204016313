import React, { useState } from "react";
import Anchor from "@atom/Anchor";
import LinkButton from "@atom/LinkButton";
import Modal from "@atom/Modal";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({
  reverse,
  title,
  subtitle,
  background,
  items,
  caption,
  trevor,
}) => {
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState();

  return (
    <div className={cn(style.worksheet)}>
      <div className={cn(style.top, reverse && style.reverse)}>
        <div className={cn(style.content)}>
          <ProgressiveImage {...background} className={cn(style.bg)} />
          <div className={cn(style.textContent)}>
            <div className={cn(style.title)}>
              <div className={cn(style.inner)}>
                <Heading type={"h2"} weight={900}>
                  {title}
                </Heading>
                <p>{subtitle}</p>
              </div>
              <ProgressiveImage
                {...background}
                className={cn(style.bgMobile)}
              />
              <div className={cn(style.hr)} />
            </div>
            <div className={cn(style.items)}>
              {items.map((value, i) => (
                <div key={i} className={cn(style.item)}>
                  <ProgressiveImage className={cn(style.center_image)} object_Position="center top" {...value.image} />
                  <div className={cn(style.text)}>
                    <div className={cn(style.upper)}>
                      <Heading type={"h5"}>{value.title}</Heading>
                      <label>{value.subtitle}</label>
                    </div>
                    {value.pdf ? (
                      <Anchor
                        href={value.pdf}
                        onClick={
                          "_gaq.push([‘_trackEvent’,’Download’,’PDF’,this.href])"
                        }
                      >
                        {value.pdfLabel}
                      </Anchor>
                    ) : (
                      <LinkButton to={value.link.url}>
                        {value.link.label}
                      </LinkButton>
                    )}
                  </div>
                  {value?.bottomText && (
                    <div className={cn(style.bottomText)}>
                      <Heading weight={700}>{value.bottomText.number}</Heading>
                      <label>{value.bottomText.caption}</label>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {caption && (
              <Anchor
                className={cn(style.caption)}
                href={
                  "https://williamsinstitute.law.ucla.edu/publications/nonbinary-lgbtq-adults-us/"
                }
              >
                {caption}
              </Anchor>
            )}
          </div>
        </div>
      </div>
      {trevor ? (
        <div className={cn(style.bottom)}>
          <div className={cn(style.content)}>
            <div className={cn(style.logo)}>
              <ProgressiveImage {...trevor.logo} />
              <Anchor href={trevor.link.url}>{trevor.link.label}</Anchor>
            </div>
            {open ? (
              <Modal close={() => setOpen(false)}>
                <ProgressiveImage
                  {...trevor.images[selected - 1]}
                  {...trevor.images[selected - 1].larger}
                />
              </Modal>
            ) : null}
            {trevor.images.map((value, i) => (
              <div
                key={i}
                className={cn(style.stats)}
                onClick={() => {
                  setSelected(i + 1);
                  setOpen(true);
                }}
              >
                <ProgressiveImage {...value} {...value.smaller} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
