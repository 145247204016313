export default {
  title: "Sources",
  secondTitle: "The State of LGBTQ+ Health Care Experiences Survey",
  secondDescription:
    "An evidence-based research initiative to develop a current-state, deeply informed view of the heath experiences of people who identify as lesbian, gay, bisexual, transgender, queer, and other sexual and gender minorities (LGBTQ+).",
  thirdDescription:
    "Between March 24 and April 7, 2022, Optum and OutCare Health conducted an online survey to a national sample of U.S. residents via a third-party partner. Participation was voluntary and constituted consent. This is the first comprehensive study that has assessed levels of satisfaction and trust from a multidimensional perspective — i.e., health care providers, health care systems and health care insurers.",
  infograph: {
    src: require("@assets/images/infograph.png"),
    placeholder: require("@assets/images/infograph_thumb.png"),
    alt: "Survey Infograph",
    width: 1190,
    height: 422,
  },
  outcareTitle: "About OutCare",
  outcareDescription:
    "Founded in 2015, OutCare Health is a nonprofit, 501(c)(3) health care equity organization that offers extensive information and education on LGBTQ+ health care in order to provide a centralized, multidisciplinary, and multidimensional solution to LGBTQ+ disparities. OutCare Health supports people who identify as LGBTQ+ at individual levels as well as promotes social and health care equity at provider, academic, institutional, and community levels. Additionally, OutCare Health recognizes the unique health needs of LGBTQ+ communities and connects them with many national resources including the OutList, Public Resource Database, Mentorship Program, Academic Research, Cultural Competency Trainings, OutTalk series, OutPost blogs, and many more. Specifically, OutCare Health has a wealth of experience in LGBTQ+ research leading to numerous publications over the past two years. For all of these efforts, OutCare Health has been the recipient of numerous local and national diversity awards.",
  sourcesTitle: "Sources",
  sources: [
    "LGBTQ+ stands for lesbian, gay, bisexual, transgender, queer/questioning (non-cisgender or heterosexual). + is used to signify gender identities and sexual orientations that are not specifically covered by the other five as a means of inclusivity.",
    "<span>7% of U.S. adults (or 20M+ Americans) identify as LGBTQ+, and that number is increasing with each new generation.</span>\nJones, J. (2022, Feb 17). LGBT identification in U.S. ticks up to 7.1%. https://news.gallup.com/poll/389792/lgbt-identification-ticks-up.aspx",
    "<span>About <strong>1 in 5</strong> identify along the LGBTQ+ spectrum among Gen Z alone.</span>",
    "The majority of Americans demonstrate allyship on key LGBTQ+ issues, with 66% of Americans saying people should be free to choose their gender pronoun and 70% say same-sex marriage should be legal. (Collage Group, Trends Study, March 2020.)",
    "<sup>1</sup> The United States Department of Health and Human Services recognizes five main SDoH categories: 1) Economic Stability, 2) Education Access and Quality, 3) Health Care Access and Quality, 4) Neighborhood and Built Environment, and 5) Social and Community Context.",
    "<sup>1</sup> Arizona House Passes Anti-Transgender Sports and Medical Care Bans. (n.d.). Human Rights Campaign.<a href='https://www.hrc.org/press-releases/breaking-arizona-house-passes-anti-transgender-sports-and-medical-care-bans' target='_blank'>https://www.hrc.org/press-releases/breaking-arizona-house-passes-anti-transgender-sports-and-medical-care-bans</a>",
    "<span>LGBTQ+-identified people are 2.5x more likely to experience depression, anxiety, and substance abuse.</span>\nSAMHSA Center for Behavior Health Statistics and Quality 2008-2021",
    "<span>LGBTQ+-identified people are also more than 2x as likely to seek care from a mental health provider.</span>\n29.7% of LGBTQ+ people have a mental health provider, compared to 13.3% of cisgender heterosexual people (per Optum and OutCare Health’s 2022 Health Experiences Survey).",
    "<sup>1</sup> Center for American Progress, <a href='https://www.americanprogress.org/article/discrimination-and-barriers-to-well-being-the-state-of-the-lgbtqi-community-in-2022/' target='_blank'>“Discrimination and Barriers to Well-Being: The State of the LGBTQI+ Community in 2022.”</a> 12 January 2023.",
    "<sup>1</sup> Patel P, Bush T, Kojic EM, et al. <a href='https://escholarship.org/uc/item/0f79p2wn' target='_blank'>Prevalence, Incidence, and Clearance of Anal High-Risk Human Papillomavirus Infection Among HIV-Infected Men in the SUN Study.</a> J Infect Dis. 2018;217(6):953-963. doi:10.1093/infdis/jix607",
    "<sup>1</sup> Bell K, Rieger E, Hirsch JK. <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6331421/' target='_blank'>Eating Disorder Symptoms and Proneness in Gay Men, Lesbian Women, and Transgender and Non-conforming Adults: Comparative Levels and a Proposed Mediational Model </a>[published correction appears in Front Psychol. 2019 Jul 10;10:1540]. Front Psychol. 2019;9:2692. doi:10.3389/fpsyg.2018.02692",
    "<sup>1</sup> Meads C, Moore D. <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3890640/' target='_blank'>Breast cancer in lesbians and bisexual women: systematic review of incidence, prevalence and risk studies.</a> BMC Public Health. 2013;13:1127. doi:10.1186/1471-2458-13-1127",
    "<sup>1</sup> Pool LR, Ning H, Lloyd-Jones DM, Allen NB. <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5634269/' target='_blank'>Trends in Racial/Ethnic Disparities in Cardiovascular Health Among US Adults From 1999-2012.</a> J Am Heart Assoc. 2017;6(9):e006027. doi:10.1161/JAHA.117.006027",
  ],
};
