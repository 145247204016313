import arrownext from "@assets/icons/arrow-next.svg";
import arrowprev from "@assets/icons/arrow-prev.svg";
import menu from "@assets/icons/menu.svg";
import pause from "@assets/icons/pause.svg";
import play from "@assets/icons/play.svg";
import quote from "@assets/icons/quote.svg";
import mute from "@assets/icons/mute.svg";
import volume from "@assets/icons/volume.svg";
import download from "@assets/icons/download.svg";
import fax from "@assets/icons/fax.svg";
import x from "@assets/icons/x.svg";
import check from "@assets/icons/check.svg";

export default {
  arrownext,
  arrowprev,
  menu,
  pause,
  play,
  quote,
  mute,
  volume,
  download,
  fax,
  x,
  check,
};
