export default {
  accesstocare: {
    title: "Access to Care",
    input: [
      {
        name: "q1",
        toplabel: "Time for reflection:",
        label:
          "Have you experienced bias, discrimination or other challenges that limited your access to care?",
      },
      {
        name: "q2",
        toplabel: "For providers serving our communities:",
        label:
          "What actions are you taking to ensure your practice is accessible to LGBTQ+ communities?",
      },
    ],
  },
  patientexperiences: {
    title: "Patient Experiences",
    input: [
      {
        name: "q1",
        toplabel: "Time for reflection:",
        label:
          "If you could change one thing about the way you experience health care, what would it be?",
      },
      {
        name: "q2",
        toplabel: "For providers serving our communities:",
        label:
          "What is the greatest barrier you face in delivering optimal experience to LGBTQ+ communities?",
      },
    ],
  },
  healthoutcomes: {
    title: "Health Outcomes",
    input: [
      {
        name: "q1",
        toplabel: "Time for reflection:",
        label:
          "Do you have any examples where you believe your health outcomes have been impacted by some of the challenges explored in this exhibit?",
      },
      {
        name: "q2",
        toplabel: "For providers serving our communities:",
        label: "What would empower you to better support LGBTQ+ communities?",
      },
    ],
  },
  acalltoaction: {
    title: "A Call to Action",
    input: [
      {
        name: "q1",
        toplabel: "Time for reflection:",
        label:
          "What changes would you like to see in order to improve the health experiences of LGBTQ+ communities?",
      },
    ],
  },
};
