import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({ toplabel, label, name, value, className, ...rest }) => (
  <div className={cn(style.textarea, className)}>
    {toplabel && <p>{toplabel}</p>}
    <label className={cn(toplabel && style.label)}>{label}</label>
    <textarea
      id={name}
      name={name}
      rows="5"
      cols="33"
      maxLength={toplabel ? "unset" : 250}
      {...rest}
    ></textarea>
  </div>
);
