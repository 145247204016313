export default {
  banners: [
    {
      src: require("@assets/images/home_1.png"),
      mobile: require("@assets/images/homemobile_1.png"),
      placeholder: require("@assets/images/home_1thumb.png"),
      mobilePlaceholder: require("@assets/images/homemobile_1thumb.png"),
      link: "/transgender-support",
    },
    {
      src: require("@assets/images/home_3.png"),
      mobile: require("@assets/images/homemobile_3.png"),
      placeholder: require("@assets/images/home_3thumb.png"),
      mobilePlaceholder: require("@assets/images/homemobile_3thumb.png"),
      link: "/resource-guides",
    },
  ],
  nonbinary: {
    title: "Celebrating your authentic self",
    subtitle:
      "Embracing the unique qualities that make you who you are, celebrating your authentic self is a powerful act of self-love.",
    background: {
      src: require("@assets/images/nonbinary.png"),
      placeholder: require("@assets/images/nonbinary_thumb.png"),
      width: 652,
      height: 652,
    },
    items: [
      {
        image: {
          src: require("@assets/images/nonbinary_article1.jpg"),
          placeholder: require("@assets/images/nonbinary_article1_thumb.jpg"),
          alt: "What does it mean to be nonbinary? Article",
          width: 320,
          height: 140,
        },
        title: "What does it mean to be nonbinary?",
        subtitle:
          "Nonbinary individuals may embody a blend of masculine and feminine aspects or feel genderless, embracing their identity in a way that aligns with their true self.",
        pdfLabel: "Read article",
        pdf: "/assets/pdf/P365+_What_does_it_mean_to_be_nonbinary.pdf",
        bottomText: {
          number: "1.2M",
          caption: "LGBTQ people in the US\nidentify as nonbinary",
        },
      },
      {
        image: {
          src: require("@assets/images/nonbinary_article2.jpg"),
          placeholder: require("@assets/images/nonbinary_article2_thumb.jpg"),
          alt: "Celebrate Pride year-round Article",
          width: 320,
          height: 140,
        },
        title: "Celebrate Pride year-round",
        subtitle:
          "Embracing and supporting the LGBTQ+ community is more than a single month. Pride is an ongoing effort to promote inclusivity, acceptance, and equality.",
        pdfLabel: "Read article",
        pdf: "/assets/pdf/P365+_Pride_year_round.pdf",
        bottomText: {
          number: "11%",
          caption: "of LGBTQ adults\nidentify as nonbinary*",
        },
      },
    ],
    caption:
      "* Nonbinary LGBTQ Adults in the United States - Williams Institute (ucla.edu)",
  },
  toolkit: {
    background: {
      src: require("@assets/images/toolkit_bg.png"),
      alt: "Pride 365+ Toolkit Materials background",
      width: 1366,
      height: 543,
    },
    title: "Pride 365+ Toolkit Materials",
    items: [
      {
        image: {
          src: require("@assets/images/toolkit_1.png"),
          placeholder: require("@assets/images/toolkit_1thumb.png"),
          alt: "Fact Sheets",
          width: 500,
          height: 300,
        },
        link: "/fact-sheets",
      },
      {
        image: {
          src: require("@assets/images/toolkit_2.png"),
          placeholder: require("@assets/images/toolkit_2thumb.png"),
          alt: "How To Be An Ally",
          width: 500,
          height: 600,
        },
        link: "/how-to-be-an-ally",
      },
      {
        image: {
          src: require("@assets/images/toolkit_3.png"),
          placeholder: require("@assets/images/toolkit_3thumb.png"),
          alt: "Resource Guide",
          width: 500,
          height: 380,
        },
        link: "/resource-guides",
      },
      {
        image: {
          src: require("@assets/images/toolkit_4.png"),
          placeholder: require("@assets/images/toolkit_4thumb.png"),
          alt: "Transgender Support",
          width: 500,
          height: 600,
        },
        link: "/transgender-support",
      },
      {
        image: {
          src: require("@assets/images/toolkit_5.png"),
          placeholder: require("@assets/images/toolkit_5thumb.png"),
          alt: "Terminology & Pronouns",
          width: 500,
          height: 300,
        },
        link: "/terminology-&-pronouns",
      },
      {
        image: {
          src: require("@assets/images/toolkit_6.png"),
          placeholder: require("@assets/images/toolkit_6thumb.png"),
          alt: "More",
          width: 500,
          height: 520,
        },
        hash: "#supportMaterials",
      },
    ],
  },
  sanvello: {
    background: {
      src: require("@assets/images/sanvello_bg.png"),
      placeholder: require("@assets/images/sanvello_thumb.png"),
      alt: "Sanvello Background",
      width: 1366,
      height: 769,
    },
    logo: {
      src: require("@assets/images/sanvello_logo.png"),
      alt: "Sanvello App Logo",
      width: 296,
      height: 151,
    },
    app: {
      src: require("@assets/images/sanvello_app.png"),
      placeholder: require("@assets/images/sanvello_appthumb.png"),
      alt: "Sanvello Mobile App",
      width: 926,
      height: 1873,
    },
    title: "You know you best.\nPick a path for the moment.",
    subtitle:
      "With self-care, coaching, therapy, and a community that gets it, Sanvello has everything you need to improve your mental health — on your terms.",
    downloadLabel: "START HERE",
    downloadSublabel:
      "Start here. Feel better. Download the top-rated app for stress, anxiety, depression, or whatever you’re going through.",
    appstore: {
      src: require("@assets/images/app_store.png"),
      alt: "Sanvello App Store",
      width: 214,
      height: 71,
    },
    appLink: "https://sanvello.app.link/PRIDE",
    googleplay: {
      src: require("@assets/images/google_play.png"),
      alt: "Sanvello Google Play",
      width: 242,
      height: 73,
    },
    googleLink: "https://sanvello.app.link/PRIDE",
    footer:
      "The premium version of the Sanvello app may be available to you at no extra cost as part of your health plan benefits. Check with your Employee Assistance Program (EAP) or Behavioral Health program benefits to learn more about how to sign up for this benefit at no additional cost to you.",
  },
  outcare: {
    background: {
      src: require("@assets/images/outcarebg.png"),
      alt: "Outcare background",
      width: 1366,
      height: 369,
    },
    logo: {
      src: require("@assets/images/outcare_logo.png"),
      alt: "OutCare Logo",
      width: 393,
      height: 100,
    },
    logoLabel:
      '<a href="https://outcarehealth.org/" target="_blank">OutCare Health</a> is a national nonprofit 501(c)(3) LGBTQ+ health equity organization that provides extensive information, education, training, consultation, resource and provider lists, mentorship, research, and much more for millions of LGBTQ+ people across the country.',
    learnMoreLabel: "Learn More",
    learnMoreLink: "https://www.outcarehealth.org/",
    info: {
      src: require("@assets/images/outcare_info.png"),
      placeholder: require("@assets/images/outcare_info_thumb.png"),
      alt: "OutCare Logo",
      width: 600,
      height: 340,
    },
    infoLabel:
      'OutCare has a number of national initiatives, including the <a href="https://outcarehealth.org/outlist" target="_blank">OutList</a> directory of LGBTQ+ culturally competent providers, <a href="https://outcarehealth.org/resources" target="_blank">LGBTQ+ Public Resource Database</a>, <a href="https://outcarehealth.org/mentorship" target="_blank">Mentorship Program</a>, <a href="https://outcarehealth.org/outtalk" target="_blank">OutTalk</a> series on diversity and intersectionality, <a href="https://www.outcarehealth.org/outreach/" target="_blank">OutReach</a> Community Building & Support Groups, <a href="https://outcarehealth.org/outposts" target="_blank">OutPost</a> blogs, <a href="https://www.outcarehealth.org/research/" target="_blank">LGBTQ+ research</a>, <a href="https://outcarehealth.org/training" target="_blank">LGBTQ+ cultural competency trainings</a>, and many more.',
    services: [
      {
        image: require("@assets/images/outlists.png"),
        title: "OutCare OutList",
        description: "Find an LGBTQ+ \ncompetent doctor near you",
        link: "https://www.outcarehealth.org/outlist/",
      },
      {
        image: require("@assets/images/outtalkk.png"),
        title: "OutCare OutTalks",
        description:
          "A series dedicated to discussing \nimportant issues in the LGBTQ+ community",
        link: "https://www.outcarehealth.org/outtalk/",
      },
      {
        image: require("@assets/images/train.png"),
        title: "OutCare Trainings",
        description:
          "We offer LGBTQ+ cultural competency \ntrainings across a range of topics and in a variety of formats.",
        link: "https://www.outcarehealth.org/training/",
      },
      {
        image: require("@assets/images/outpost.png"),
        title: "OutCare OutReach",
        description:
          "Build community through engaging programming for the LGBTQ+ community",
        link: "https://www.outcarehealth.org/outreach/",
      },
    ],
  },
  supportMaterials: {
    title: "Pride 365+ Support Materials",
    background: {
      src: require("@assets/images/links_bg.png"),
      placeholder: require("@assets/images/links_thumb.png"),
      alt: "Pride 365+ Support Materials",
      width: 1812,
      height: 1075,
    },
    items: [
      {
        image: require("@assets/images/link1.png"),
        title: "Poster & Flyer",
        description:
          "Let’s take pride in creating an open, safe and respectful workplace and world, where every person counts.",
        link: "/poster-&-flyer",
      },
      {
        image: require("@assets/images/link2.png"),
        title: "Email & Logo",
        description:
          "Add your pronouns to your auto email signature. Another way to support the people you work with.",
        link: "/emails",
      },
      {
        image: require("@assets/images/link3.png"),
        title: "Social",
        description:
          "Colleague, friend, child, sibling, partner. Person. We respect and support your truth. All are welcome here.",
        link: "/media",
      },
    ],
  },
};
