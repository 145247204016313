import React from "react";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import PageCard from "@molecule/PageCard";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => (
  <div className={cn(style.root)}>
    <PageCard pageCardClass={cn(style.resourceGuide)}>
      <Heading withMargin={true}>{data.title}</Heading>
      <Heading type={"h3"} withMargin={true}>
        {data.secondTitle}
      </Heading>
      <p>{data.secondDescription}</p>
      <br />
      <p>{data.thirdDescription}</p>
      <br />
      <ProgressiveImage {...data.infograph} />
      <Heading type={"h3"} withMargin={true}>
        {data.outcareTitle}
      </Heading>
      <p>{data.outcareDescription}</p>
      <br />
      <Heading type={"h3"} withMargin={true}>
        {data.sourcesTitle}
      </Heading>
      <div className={cn(style.sources)}>
        {data.sources.map((value, i) => (
          <LabelwithEmphasis key={i} className={cn(style.source)}>
            {value}
          </LabelwithEmphasis>
        ))}
      </div>
    </PageCard>
  </div>
);
