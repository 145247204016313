import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import OptumLogo from "@assets/images/optum_logo.svg";
import Button from "@atom/Button";
import Icon from "@atom/Icon";
import Input from "@atom/Input";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import ProgressiveImage from "@atom/ProgressiveImage";
import { PdfDownload } from "@molecule/ImagewithLabel";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

const PrintPreview = React.forwardRef(({ formInput }, ref) => (
  <div className={cn(style.worksheet, style.print)} ref={ref}>
    <img src={data.prideLogo} />
    <div className={cn(style.hr)} />
    <div className={cn(style.body)}>
      <Heading>{data.title}</Heading>
      <p>{data.subtitle}</p>
      <div className={cn(style.questions)}>
        {data.questions.map((value, i) => (
          <div className={cn(style.item)}>
            <label>{value}</label>
            <div className={cn(style.box)}>{formInput[value]}</div>
          </div>
        ))}
      </div>
      <OptumLogo />
      {data.footer.map((value, i) => (
        <LabelwithEmphasis key={i} className={style.footer}>
          {value}
        </LabelwithEmphasis>
      ))}
    </div>
  </div>
));

export default () => {
  const [formInput, setFormInput] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleChange = (e, name) => {
    setFormInput((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  return (
    <div className={cn(style.worksheet)}>
      <ProgressiveImage {...data.background} className={cn(style.bg)} />
      <Heading>{data.title}</Heading>
      <p>{data.subtitle}</p>
      <PdfDownload pdf={data.download.pdf}>
        {data.download.label}&nbsp;
        <Icon icon="download" className={cn(style.download)} />
      </PdfDownload>
      <div className={cn(style.questions)}>
        {data.questions.map((value, i) => (
          <Input
            key={i}
            label={value + ":"}
            name={value}
            className={cn(style.item)}
            value={formInput[value] || ""}
            onChange={(e) => handleChange(e, value)}
          />
        ))}
      </div>
      <Button
        onClick={(e) => {
          handlePrint(e);
        }}
      >
        <Icon icon="fax" className={cn(style.fax)} />
        {data.printLabel}
      </Button>
      <div className={cn(style.none)}>
        <PrintPreview ref={componentRef} formInput={formInput} />
      </div>
    </div>
  );
};
